import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Container, Row, Col} from "react-bootstrap"

class Swimming extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Swimming" />
        <Container>
          <Row>
            <Col sm={12}>
            <h1 className='pt-5'>Want to go swimming in The Lake District?</h1>
            <h5>Here are some useful resources:</h5>
            <ul>
              <li><a href="https://www.lakedistrict.gov.uk/visiting/things-to-do/water/swimming">Swimming in The Lake District - lakedistrict.gov.uk</a></li>
              <li><a href="https://www.sallyscottages.co.uk/blog/9-wild-swimming-spots-lake-district">9 Wild Swimming Spots in the Lake District - Sally's Cottages</a></li>
              <li><a href="https://www.swimthelakes.co.uk/8276/which-lakes-can-you-swim-in-the-lake-district/">Which Lakes Can You Swim In The Lake District? - Swim the Lakes</a></li>
              <li><a href="https://www.lakelandhideaways.co.uk/guides/open-water-swimming-lake-district">Open water swimming in The Lake District - Lakeland Hideaways</a></li>
            </ul>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Swimming

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
